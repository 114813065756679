<template>
  <div>
    <van-nav-bar
        :title="title"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-badge :content="productLackStockNum">
          <van-switch v-model="stockNotEnoughSwitchChecked" active-color="#ee0a24" inactive-color="#1989fa" size="20px" @change="changeStockNotEnoughSwitch" />
        </van-badge>
      </template>
    </van-nav-bar>
    <div>
      <van-search
          v-model="searchParamValue"
          show-action
          label="商品"
          placeholder="请输入商品关键词"
          @search="onSearch"
          @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">分类</div>
        </template>
      </van-search>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="boundary-div" v-for="item in dataList" :key="item.productId" :name="item.productId">
          <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">{{ item.keyword }}
          </van-divider>
          <van-card
            :title="item.keyword"
            :thumb="item.image"
            @click-thumb="onClickThumb(item.sliderImage)"
          >
            <template #desc>
              <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; width: 200px; color: #07c160">
                共{{ item.stock }}{{ item.sunitName }}
                每{{ item.zunitName }}{{ item.zunitNum }}{{ item.sunitName }}
              </div>
              <div>
                {{ item.skuStr }}
              </div>
            </template>
            <template #tags>
              <van-badge style="margin-top: 5px;" v-if="item.stockWarn" :content="item.attrNum">
                <van-tag v-if="item.stockWarn" plain type="danger">缺货</van-tag>
              </van-badge>
              <div style="margin-top: 5px; margin-bottom: 3px;">
              <van-tag style="margin-right: 3px;" plain type="primary" v-for="subTagI in item.productTagInfoDtoList" :key="subTagI.tag">{{ subTagI.desc }}
              </van-tag>
              </div>
            </template>
            <template #price>
              <div>
                <span style="margin-left: 3px;" class="stock" v-bind:class="{ 'stockWarn': item.stockWarn } ">
                  {{ item.znum }}{{ item.zunitName }}<span
                  style="font-weight: 400; font-size: 15px;">{{ item.snum }}{{ item.sunitName }}</span>
                </span>
              </div>
            </template>
            <template #footer>
              <van-button size="mini" @click="onClickProductEdit(item)">设置</van-button>
            </template>
          </van-card>
          <van-collapse v-model="activeNames" accordion @change="onChange">
            <van-collapse-item :name="item.productId" icon="other-pay">
              <template #title>
                <div>{{item.keyword}} 全规格</div>
              </template>
              <div v-for="subItem in stockDataList" :key="subItem.sku" :name="subItem.id">
                <div class="van-cell van-cell--center van-cell--clickable van-contact-list__item" role="button"
                     tabindex="0">
                  <div class="van-cell__value van-cell__value--alone van-contact-list__item-value">
                    {{ subItem.sku }}：
                    <span style="margin-left: 3px;" class="stock" v-bind:class="{ 'stockWarn': subItem.stockWarn } ">
                      {{ subItem.znum }}{{ subItem.zunitName }}<span
                      style="font-weight: 400; font-size: 15px;">{{ subItem.snum }}{{ subItem.sunitName }}</span>
                    </span><span style="font-size: 10px;">（共{{ subItem.stock }}{{ subItem.sunitName }}）</span>
                    <van-tag v-if="subItem.stockWarn" plain type="danger">缺货</van-tag>
                  </div>
                  <div role="radio" class="van-radio van-contact-list__radio" tabindex="0" aria-checked="true">
                    <van-button plain hairline type="primary" size="mini" @click="onShowEditStockPopup(subItem)">设置
                    </van-button>
                  </div>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
      </van-list>
    </van-pull-refresh>

    <van-popup v-model="editStockPopupShow" :style="{ height: '45%' }" position="bottom">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-dropdown-menu>
            <van-dropdown-item v-model="zunitNameS" :disabled="dropdownDisabled" :options="zUnitNameOption"/>
            <van-dropdown-item v-model="zunitNum" :disabled="dropdownDisabled" :options="zUnitNumOption"
                               @change="calculateUnitNum(3)"/>
            <van-dropdown-item v-model="sunitNameS" :disabled="dropdownDisabled" :options="sUnitNameOption"/>
          </van-dropdown-menu>
          <van-field v-model="stockWarnNum" type="number" label="低库存阈值（件数）"/>
          <van-field name="radio" label="单选框">
            <template #input>
              <van-radio-group v-model="operateType" direction="horizontal">
                <van-radio name="1" @click="onChangeOperateType(1)">加</van-radio>
                <van-radio name="2" @click="onChangeOperateType(1)">减</van-radio>
                <van-radio name="3" @click="onChangeOperateType(2)">覆盖</van-radio>
              </van-radio-group>
            </template>
          </van-field>

          <van-cell-group inset>
            <!-- 输入任意文本 -->
            <van-field v-model="zNum" type="number" label="包装数" @blur="calculateUnitNum(1)"/>
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model="sNum" type="number" label="散装数" @blur="calculateUnitNum(2)"/>
            <!-- 允许输入正整数，调起纯数字键盘 -->
            <van-field v-model="num" type="number" label="总数" @blur="calculateUnitNum(3)"/>
          </van-cell-group>

        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
    <itemSelectPopup ref="itemSelectPopup"></itemSelectPopup>

    <van-popup v-model="productEditPopupShow" position="bottom" :style="{ height: '30%',width: '100%'}">
      <van-notice-bar
        wrapable
        :scrollable="false"
        :text="productTagPopupData.name + '规格' + '【'+productTagPopupData.sku+'】' + '设置'"
      />
<!--      <van-field name="checkboxGroup" label="标签">-->
<!--        <template #input>-->
<!--          <van-checkbox-group v-model="productTagPopupData.tagList" direction="horizontal">-->
<!--            <div v-for="(item, index) in tagDataList" :key="index">-->
<!--              <van-checkbox :name="item.tag" shape="square" @click="onClickTagCheckbox">{{item.desc}}</van-checkbox>-->
<!--            </div>-->
<!--          </van-checkbox-group>-->
<!--        </template>-->
<!--      </van-field>-->
<!--      <van-field name="checkbox" label="复选框">-->
<!--        <template #input>-->
<!--          <van-checkbox v-model="checkbox" shape="square" />-->
<!--        </template>-->
<!--      </van-field>-->
      <van-field name="checkboxGroup" label="标签">
        <template #input>
          <van-button type="danger" @click="onClickTagButton">滞销</van-button>
        </template>
      </van-field>
      <van-field name="checkboxGroup" label="标签">
        <template #input>
          <van-checkbox-group v-model="productTagPopupData.tagList" direction="horizontal">
            <van-checkbox name="zhixiao" shape="square" @click="onClickTagCheckbox">滞销</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>

    </van-popup>

    <van-image-preview v-model="previewShow" :images="previewImages" @change="onPreviewChange" @close="onPreviewClose">
      <template v-slot:index>第{{ previewIndex }}页</template>
    </van-image-preview>
  </div>
</template>

<script>
import {
  Badge,
  Card,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  ContactList,
  Divider,
  DropdownItem,
  DropdownMenu,
  Field,
  Form, ImagePreview,
  List,
  NavBar,
  NoticeBar,
  PullRefresh,
  Radio,
  RadioGroup,
  Search,
  ShareSheet,
  Switch,
  Tag
} from 'vant';
import * as stockAdminService from "@/api/admin/stock";
import * as adminPriceService from "@/api/admin/price";
import * as adminProductService from "@/api/admin/product";

import itemSelectPopup from "@/components/itemSelectPopup";
import {mapState} from 'vuex'

export default {
  components: {
    [Card.name]: Card,
    [ContactList.name]: ContactList,
    [ShareSheet.name]: ShareSheet,
    [NavBar.name]: NavBar,
    [Badge.name]: Badge,
    [Switch.name]: Switch,
    [Search.name]: Search,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Form.name]: Form,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Tag.name]: Tag,
    [Divider.name]: Divider,
    itemSelectPopup: itemSelectPopup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [NoticeBar.name]: NoticeBar,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      zUnitNameOption: [
        {text: '箱', value: 0},
        {text: '件', value: 1},
      ],
      zUnitNumOption: [
        {text: 20, value: 20},
        {text: 25, value: 25},
        {text: 30, value: 30},
      ],
      sUnitNameOption: [
        {text: '双', value: 0},
        {text: '床', value: 1},
      ],
      zUnitNames: [
        "箱", '件'
      ],
      sUnitNames: ['双', '床'],
      showShare: false,
      options: [],
      title: '商品库存',
      searchParamValue: '',
      searchParam: {},
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      activeNames: [],
      editStockPopupShow: false,
      stockDataList: [],
      operateType: '1',
      zunitName: null,
      sunitName: null,
      zunitNameS: 0,
      sunitNameS: 0,
      zunitNum: 25,
      stockWarnNum: 5,
      zNum: 0,
      sNum: 0,
      num: 0,
      dropdownDisabled: true,
      productId: null,
      sku: null,
      stockNotEnoughSwitchChecked: false,
      productLackStockNum: null,
      productEditPopupShow: false,
      productTagPopupData: {
        name: '',
        productId: null,
        sku: '',
        tagList: [],
      },
      tagDataList: [{
        name: '',
        desc: ''
      }],
      previewShow: false,
      previewIndex: 0,
      previewImages: [
        'https://img.yzcdn.cn/vant/apple-1.jpg',
        'https://img.yzcdn.cn/vant/apple-2.jpg',
      ],
    };
  },
  watch: {
    cate: {
      handler(newVal) {
        if (newVal.cateId === undefined) {
          return
        }
        this.onSelectCate(newVal.cateId)
      },
      immediate: true,
    }
  },
  computed: {
    ...mapState({
      cate: state => state.order.cate,
    }),
  },
  created() {
    if (this.$route.params && this.$route.params.tag) {
      this.searchParam.tag = this.$route.params.tag
    }
    this.getProductTagData()
    this.getUnitData()
    this.getProductLackStockNum()
  },
  methods: {
    getProductLackStockNum() {
      let param = {
        tag: this.$route.params.tag
      }
      stockAdminService.getProductLackStockNum(param).then(res => {
        if (res.status === 200) {
          this.productLackStockNum = res.data
        }
      });
    },
    onSelectCate(cateId) {
      this.searchParamValue = '条件筛选'
      this.searchParam.sid = cateId
      this.onRefresh();
    },
    onChangeOperateType(o) {
      if (o === 1) {
        this.dropdownDisabled = true
      }
      if (o === 2) {
        this.dropdownDisabled = false
      }
    },
    onChange(activeName) {
      this.stockDataList = []
      if (activeName === undefined || activeName === null || activeName === '') {
        return
      }
      this.getWholesaleProductStockByProduct(activeName)
    },
    getWholesaleProductStockByProduct(productId) {
      let param = {
        stockNotEnough: this.stockNotEnoughSwitchChecked,
        tag: this.$route.params.tag
      }
      adminPriceService.getWholesaleProductStockByProduct(productId, param).then(res => {
        if (res.status === 200) {
          this.stockDataList = res.data
        }
      })
    },
    onShowEditStockPopup(item) {
      console.log(item)
      this.productId = item.productId
      this.sku = item.sku
      this.zunitNum = item.zunitNum
      this.zunitNameS = this.zUnitNames.indexOf(item.zunitName)
      this.sunitNameS = this.sUnitNames.indexOf(item.sunitName)
      this.stockWarnNum = item.stockWarnNum
      this.editStockPopupShow = true
    },
    calculateUnitNum(o) {
      // 包装数 = 总数 / 每包装数量
      if (o === 1) {
        // 修改包装数
        this.num = Number(this.zNum) * Number(this.zunitNum) + Number(this.sNum)
      } else if (o === 2) {
        // 修改散装数
        this.num = Number(this.zNum) * Number(this.zunitNum) + Number(this.sNum)
      } else if (o === 3) {
        // 修改总数
        this.zNum = parseInt(Number(this.num) / Number(this.zunitNum))
        this.sNum = Number(this.num) % Number(this.zunitNum)
      }
    },
    getUnitData() {
      adminPriceService.getUnitData().then(res => {
        if (res.status === 200) {
          let sUnitNameOptions = []
          res.data.sUnitNames.forEach((item, index) => {
            const sUnitNameItem = {text: item, value: index}
            sUnitNameOptions.push(sUnitNameItem)
          })
          this.sUnitNameOption = sUnitNameOptions

          let zUnitNameOptions = []
          res.data.zUnitNames.forEach((item, index) => {
            const dataItem = {text: item, value: index}
            zUnitNameOptions.push(dataItem)
          })
          this.zUnitNameOption = zUnitNameOptions

          let zUnitNumOptions = []
          res.data.zUnitNums.forEach((item, index) => {
            const dataItem = {text: Number(item), value: Number(item)}
            zUnitNumOptions.push(dataItem)
          })
          this.zUnitNumOption = zUnitNumOptions
        }
      })
    },
    getProductTagData() {
      adminProductService.getProductTag().then(res => {
        if (res.status === 200) {
          this.tagDataList = res.data
        }
      })
    },
    onSubmit() {
      const param = {
        productId: this.productId,
        sku: this.sku,
        num: this.num,
        operateType: this.operateType,
        zunitName: this.zUnitNames[this.zunitNameS],
        sunitName: this.sUnitNames[this.sunitNameS],
        zunitNum: this.zunitNum,
        stockWarnNum: this.stockWarnNum
      }
      if (this.operateType === '1' || this.operateType === '2') {
        adminPriceService.updateStock(param).then(res => {
          if (res.status === 200) {
            this.$toast('提交成功')
            this.editStockPopupShow = false
            this.getWholesaleProductStockByProduct(this.productId)
            this.onRefresh();
          }
        })
      }

      if (this.operateType === '3') {
        adminPriceService.coverStock(param).then(res => {
          if (res.status === 200) {
            this.editStockPopupShow = false
            this.getWholesaleProductStockByProduct(this.productId)
            this.onRefresh();
          }
        })
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onSearchClear() {
      this.searchParam.storeName = null
      this.searchParam.cateId = null
      this.searchParam.sid = null
      this.onRefresh();
    },
    onSearch() {
      this.searchParam.storeName = this.searchParamValue;
      this.onRefresh();
    },
    showPopup() {
      this.$refs.itemSelectPopup.isPopupShow = true
    },
    onLoad() {
      console.log('onload')
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      let that = this;
      console.log(this.searchParam)
      adminPriceService.getWholesaleProductStock(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          console.log(' that.dataList',  that.dataList)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
        }
      });
    },
    onRefresh() {
      console.log('父亲刷新我')
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      this.zNum = 0
      this.sNum = 0
      this.num = 0
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
    changeStockNotEnoughSwitch() {
      this.activeNames = []
      this.stockDataList = []
      this.searchParam.stockNotEnough = this.stockNotEnoughSwitchChecked;
      this.onRefresh();
    },
    onClickProductEdit(data) {
      this.productEditPopupShow = true
      this.productTagPopupData = {
        name: data.keyword,
        productId: data.productId,
        tagList: data.tagList,
        sku: ''
      }
      if (data.sku !== undefined && data.sku !== null) {
        this.productTagPopupData.sku = data.sku
      }
    },
    onClickTagCheckbox() {
      let pData = {
        productId: this.productTagPopupData.productId,
        tagList: this.productTagPopupData.tagList,
        sku: ''
      }
      if (this.productTagPopupData.sku !== undefined && this.productTagPopupData.sku !== null && this.productTagPopupData.sku !== '') {
        pData.sku = this.productTagPopupData.sku
      }
      adminProductService.setProductTag(pData).then(res => {
        if (res.status === 200) {
          this.$toast('设置成功')
          this.productEditPopupShow = false
          this.onRefresh()
        }
      })
    },
    onClickTagButton() {
      let pData = {
        productId: this.productTagPopupData.productId,
        tagList: ['zhixiao'],
        sku: ''
      }
      if (this.productTagPopupData.sku !== undefined && this.productTagPopupData.sku !== null && this.productTagPopupData.sku !== '') {
        pData.sku = this.productTagPopupData.sku
      }
      adminProductService.setProductTag(pData).then(res => {
        if (res.status === 200) {
          this.$toast('设置成功')
          this.productEditPopupShow = false
          this.onRefresh()
        }
      })
    },
    onClickThumb(sliderImage) {
      this.previewImages = sliderImage.split(',')
      this.previewShow = !this.previewShow
    },
    onPreviewChange(index) {
      this.previewIndex = index
    },
    onPreviewClose() {
      this.previewImages = []
    }
  }
};
</script>

<style lang="less">
.stock {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0bb20c;
}

.stockWarn {
  color: red;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
.boundary-div {
  position: relative;
  //text-align: center;
  //justify-content: center;
  //align-items: center;
  width: 100%;
}
.boundary-div::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}
</style>
